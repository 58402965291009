import _global3 from "../internals/global";
import _domIterables from "../internals/dom-iterables";
import _domTokenListPrototype from "../internals/dom-token-list-prototype";
import _esArray from "../modules/es.array.iterator";
import _createNonEnumerableProperty from "../internals/create-non-enumerable-property";
import _wellKnownSymbol from "../internals/well-known-symbol";
var _global2 = _global3;
var DOMIterables = _domIterables;
var DOMTokenListPrototype = _domTokenListPrototype;
var ArrayIteratorMethods = _esArray;
var createNonEnumerableProperty = _createNonEnumerableProperty;
var wellKnownSymbol = _wellKnownSymbol;
var ITERATOR = wellKnownSymbol("iterator");
var TO_STRING_TAG = wellKnownSymbol("toStringTag");
var ArrayValues = ArrayIteratorMethods.values;

var handlePrototype = function (CollectionPrototype, COLLECTION_NAME) {
  if (CollectionPrototype) {
    // some Chrome versions have non-configurable methods on DOMTokenList
    if (CollectionPrototype[ITERATOR] !== ArrayValues) try {
      createNonEnumerableProperty(CollectionPrototype, ITERATOR, ArrayValues);
    } catch (error) {
      CollectionPrototype[ITERATOR] = ArrayValues;
    }

    if (!CollectionPrototype[TO_STRING_TAG]) {
      createNonEnumerableProperty(CollectionPrototype, TO_STRING_TAG, COLLECTION_NAME);
    }

    if (DOMIterables[COLLECTION_NAME]) for (var METHOD_NAME in ArrayIteratorMethods) {
      // some Chrome versions have non-configurable methods on DOMTokenList
      if (CollectionPrototype[METHOD_NAME] !== ArrayIteratorMethods[METHOD_NAME]) try {
        createNonEnumerableProperty(CollectionPrototype, METHOD_NAME, ArrayIteratorMethods[METHOD_NAME]);
      } catch (error) {
        CollectionPrototype[METHOD_NAME] = ArrayIteratorMethods[METHOD_NAME];
      }
    }
  }
};

for (var COLLECTION_NAME in DOMIterables) {
  handlePrototype(_global2[COLLECTION_NAME] && _global2[COLLECTION_NAME].prototype, COLLECTION_NAME);
}

handlePrototype(DOMTokenListPrototype, "DOMTokenList");
export default {};